import * as React from "react"
import axios from "axios"
import StoreSettingsMainInCont from "./storeSettingsMainInCont"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "55%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const StoreSettingsMain = ({
  user,
  kitchenSettings,
  dispatchFeedback,
  storeSettings,
}) => {
  const [loading, setLoading] = React.useState(true)
  const [storeSettingsNow, setStoreSettingsNow] = React.useState({})

  const classes = useStyles()

  React.useEffect(() => {
    axios
      .get(
        process.env.GATSBY_STRAPI_URL + "/api/store-setting/get-store-setting"
      )
      .then(response => {
        setLoading(false)
        setStoreSettingsNow({ ...response.data })
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
        handleError(error, dispatchFeedback)
      })
  }, [dispatchFeedback])

  return (
    <>
      {loading || Object.keys(storeSettingsNow).length < 1 ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      ) : (
        <StoreSettingsMainInCont
          user={user}
          kitchenSettings={kitchenSettings}
          dispatchFeedback={dispatchFeedback}
          storeSettings={storeSettings}
          storeSettingsNow={storeSettingsNow}
          setStoreSettingsNow={setStoreSettingsNow}
        />
      )}
    </>
  )
}

export default StoreSettingsMain
