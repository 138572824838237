const toTime = time => {
  const digToText = dig => {
    return `${dig > 9 ? `${dig}` : `0${dig}`}`
  }
  let hr = Math.floor(time / 60)
  let min = time - hr * 60
  let pm = hr >= 12 && hr < 24 ? true : false
  let hrToUse = hr >= 13 ? hr - 12 : hr

  return `${digToText(hrToUse)}:${digToText(min)} ${pm ? "pm" : "am"}`
}

export default toTime
