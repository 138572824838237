import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import SwitchLarge from "./common/switchLarge"
import TimeCont from "./timeCont"
import CustomMessage from "./customMessage"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "0.5rem",
  },
  mainBox: {
    width: "100%",
    paddingTop: "5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "1rem",
  },

  headingText: {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    overflowWrap: "break-word",
    marginBottom: "0.25rem",
  },
  gridItem: {},

  secondContainer: {
    width: "100%",
    marginBottom: "5rem",
  },

  gridBox: {},
  divider: {
    height: "2px",
  },
}))

const StoreSettingsMainInCont = ({
  user,
  dispatchFeedback,
  kitchenSettings,
  storeSettings,
  storeSettingsNow,
  setStoreSettingsNow,
}) => {
  const classes = useStyles()
  const matchesMD = useMediaQuery(theme => theme.breakpoints.down("sm"))

  return (
    <>
      <Box classes={{ root: classes.mainBox }}>
        <Typography variant="h5" classes={{ root: classes.headingText }}>
          {"Online Systems Control"}
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent={`${matchesMD ? "space-between" : "flex-start"}`}
        alignItems="center"
        classes={{ root: classes.mainContainer }}
      >
        <Grid item classes={{ root: classes.gridItem }}>
          <SwitchLarge
            user={user}
            dispatchFeedback={dispatchFeedback}
            dataIn={storeSettingsNow}
            setDataIn={setStoreSettingsNow}
            label={"Dine-In"}
            field={"isDineInClosed"}
          ></SwitchLarge>
        </Grid>
        <Grid item classes={{ root: classes.gridItem }}>
          <SwitchLarge
            user={user}
            dispatchFeedback={dispatchFeedback}
            dataIn={storeSettingsNow}
            setDataIn={setStoreSettingsNow}
            label={"Take Away"}
            field={"isTakeAwayClosed"}
          ></SwitchLarge>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomMessage
            user={user}
            dispatchFeedback={dispatchFeedback}
            dataIn={storeSettingsNow}
            setDataIn={setStoreSettingsNow}
            label={"Custom Closed System Message"}
            field={"customErrorMessage"}
          ></CustomMessage>
        </Grid>
      </Grid>
      <Divider classes={{ root: classes.divider }} />

      <Box classes={{ root: classes.secondContainer }}>
        <TimeCont
          user={user}
          dispatchFeedback={dispatchFeedback}
          dataIn={storeSettingsNow}
          setDataIn={setStoreSettingsNow}
        ></TimeCont>
      </Box>
    </>
  )
}

export default StoreSettingsMainInCont
