import React from "react"
import TimeComp from "./common/timeComp"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { IsEqual } from "lodash"

import handleError from "../../../helperFunc/handleError"
import handleSuccess from "../../../helperFunc/handleSuccess"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainGrid: {
    height: "4rem",
  },
  sliderBox: {
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  textGrid: { marginBottom: "2rem" },

  mainContainer: {
    width: "100%",
    paddingTop: "2.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    marginBottom: "1rem",
  },

  secondContainer: {
    width: "100%",
  },
  headingText: {
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    overflowWrap: "break-word",
    marginBottom: "0.25rem",
  },
}))

let days = [
  { O: "monO", C: "monC", name: "Monday", nameShort: "Mon" },
  { O: "tueO", C: "tueC", name: "Tuesday", nameShort: "Tue" },
  { O: "wedO", C: "wedC", name: "Wednesday", nameShort: "Wed" },
  { O: "thuO", C: "thuC", name: "Thursday", nameShort: "Thu" },
  { O: "friO", C: "friC", name: "Friday", nameShort: "Fri" },
  { O: "satO", C: "satC", name: "Saturday", nameShort: "Sat" },
  { O: "sunO", C: "sunC", name: "Sunday", nameShort: "Sun" },
]

let daysObjKeys = [
  "monO",
  "monC",
  "tueO",
  "tueC",
  "wedO",
  "wedC",
  "thuO",
  "thuC",
  "friO",
  "friC",
  "satO",
  "satC",
  "sunO",
  "sunC",
]

export default function TimeCont({
  dataIn,
  user,
  dispatchFeedback,
  setDataIn,
}) {
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)
  const [timeObj, setTimeObj] = React.useState(
    Object.keys({ ...dataIn })
      .filter(key => daysObjKeys.includes(key))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: dataIn[key],
        })
      }, {})
  )

  const timeObjRef = React.useRef(
    Object.keys({ ...dataIn })
      .filter(key => daysObjKeys.includes(key))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: dataIn[key],
        })
      }, {})
  )
  const matchesMD = useMediaQuery(theme => theme.breakpoints.down("sm"))

  const handleSend = () => {
    setIsLoading(true)
    let dataInCopy = { ...dataIn, ...timeObj }

    axios
      .put(
        process.env.GATSBY_STRAPI_URL + "/api/store-setting",
        {
          data: {
            ...timeObj,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setIsLoading(false)
        handleSuccess("New operating hours successfully set", dispatchFeedback)
        timeObjRef.current = Object.keys({ ...dataInCopy })
          .filter(key => daysObjKeys.includes(key))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: dataInCopy[key],
            })
          }, {})

        setDataIn(dataInCopy)
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent={`${matchesMD ? "space-between" : "flex-start"}`}
        alignItems="center"
        classes={{ root: classes.mainContainer }}
      >
        <Grid item xs={7} md={9} lg={9} xl={8}>
          <Typography variant="h5" classes={{ root: classes.headingText }}>
            {"System Operating Hours"}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            disabled={IsEqual(timeObjRef.current, timeObj) || isLoading}
            onClick={handleSend}
            variant="contained"
            color="secondary"
            size="large"
            startIcon={
              isLoading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                <DoneOutlinedIcon />
              )
            }
          >
            {"Save"}
          </Button>
        </Grid>
      </Grid>

      {days.map((day, index) => (
        <TimeComp
          index={index}
          key={index}
          user={user}
          dispatchFeedback={dispatchFeedback}
          timeObj={timeObj}
          setTimeObj={setTimeObj}
          openKey={day.O}
          closeKey={day.C}
          name={day.name}
          nameShort={day.nameShort}
        ></TimeComp>
      ))}
    </>
  )
}
