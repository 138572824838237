import React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import InputBase from "@material-ui/core/InputBase"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined"
import CircularProgress from "@material-ui/core/CircularProgress"

import handleError from "../../../helperFunc/handleError"
import handleSuccess from "../../../helperFunc/handleSuccess"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  titleText: {
    fontWeight: 500,
    marginTop: "0.5rem",
    marginBottom: "0.25rem",
    marginLeft: "0.5rem",
    lineHeight: "1rem",
    fontSize: "1rem",
    textAlign: "left",
  },
  paper: {
    backgroundColor: `${theme.palette.common.blackTwo} !important`,
    display: "inline-block",
    flexWrap: "wrap",
    borderRadius: "1.5rem",
    marginBottom: "1rem",
    marginRight: "1rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "0.5rem",

    width: "100%",
  },
  textField: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    borderRadius: "1rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    width: "100%",
  },
  butEndIcon: {
    margin: 0,
    padding: 0,
  },
  but: {
    padding: "0.5rem",
    borderRadius: "6rem",
    height: "3rem !important",
    width: "3rem !important",
    minWidth: "3rem !important",
  },
}))

export default function CustomMessage({
  dataIn,
  label,
  field,
  user,
  dispatchFeedback,
  setDataIn,
}) {
  const [value, setValue] = React.useState(dataIn[field])
  const [isLoading, setIsLoading] = React.useState(false)

  const handleChange = event => {
    setValue(event.target.value)
  }

  const handleSend = () => {
    if (value.length < 500) {
      setIsLoading(true)
      let dataInCopy = { ...dataIn }
      dataInCopy[field] = value
      axios
        .put(
          process.env.GATSBY_STRAPI_URL + "/api/store-setting",
          {
            data: {
              [`${field}`]: dataInCopy[field],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          setIsLoading(false)
          handleSuccess("Message successfully set", dispatchFeedback)
          setDataIn(dataInCopy)
        })
        .catch(error => {
          setIsLoading(false)
          console.error(error)
          handleError(error, dispatchFeedback)
        })
    } else {
      handleError(
        "Message must be smaller than 500 characters",
        dispatchFeedback
      )
    }
  }

  const classes = useStyles()

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h6" className={classes.titleText}>
          {`${label}`}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={10}>
            <InputBase
              disabled={isLoading}
              className={classes.textField}
              id="standard-multiline-static"
              label="Multiline"
              placeholder="Enter custom message here"
              multiline
              minRows={2}
              maxRows={4}
              value={value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={value === dataIn[field] || isLoading}
              aria-label="done"
              variant="contained"
              color="secondary"
              onClick={handleSend}
              classes={{ endIcon: classes.butEndIcon, root: classes.but }}
              endIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={"1.75rem"} />
                ) : (
                  <DoneOutlinedIcon />
                )
              }
            ></Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
