import React from "react"
import axios from "axios"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import handleError from "../../../../helperFunc/handleError"

import { makeStyles, withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  titleText: {
    fontWeight: 500,
    marginTop: "0.5rem",
    lineHeight: "1rem",
    fontSize: "1rem",
    textAlign: "center",
  },
  paper: {
    backgroundColor: `${theme.palette.common.blackTwo} !important`,
    display: "inline-block",
    flexWrap: "wrap",
    borderRadius: "1.5rem",
    minWidth: "9.5rem",
    marginBottom: "1rem",
    [theme.breakpoints.up("md")]: {
      marginRight: "1rem",
    },
    right: 0,
  },

  loadingBox: {
    paddingTop: "0.75rem",
    paddingBottom: "0.25rem",
    textAlign: "center",
  },

  alertTitle: {
    fontSize: "1.75rem !important",
    marginTop: "0.5rem",
    lineHeight: "2rem !important",
  },

  dialogText: {
    fontSize: "1.25rem !important",
    lineHeight: "1.5rem",
  },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  but: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
}))

const StyledToggleButtonGroup = withStyles(theme => ({
  root: {
    flexWrap: "wrap",
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
  },
  grouped: {
    border: "none",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup)

const StyledToggleButtonOn = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    borderTopRightRadius: "2rem !important",
    borderBottomRightRadius: "2rem !important",

    padding: "0rem 0.75rem",
    fontWeight: 600,
    fontSize: "1.5rem !important",
  },
  selected: {
    backgroundColor: `${theme.palette.common.midGreen} !important`,
  },
}))(ToggleButton)

const StyledToggleButtonOff = withStyles(theme => ({
  root: {
    backgroundColor: `${theme.palette.common.blackThree} !important`,
    borderTopLeftRadius: "2rem !important",
    borderBottomLeftRadius: "2rem !important",
    padding: "0rem 0.75rem",
    fontWeight: 600,
    fontSize: "1.5rem !important",
  },
  selected: {
    backgroundColor: `${theme.palette.common.redSelected} !important`,
  },
}))(ToggleButton)

export default function SwitchLarge({
  dataIn,
  label,
  field,
  user,
  dispatchFeedback,
  setDataIn,
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleSelectedChange = (e, change) => {
    if (change !== null) {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = () => {
    setOpen(false)
    setIsLoading(true)
    let dataInCopy = { ...dataIn }
    dataInCopy[field] = !dataInCopy[field]

    axios
      .put(
        process.env.GATSBY_STRAPI_URL + "/api/store-setting",
        {
          data: {
            [`${field}`]: dataInCopy[field],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        }
      )
      .then(response => {
        setIsLoading(false)
        setDataIn(dataInCopy)
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
        handleError(error, dispatchFeedback)
      })
  }

  const classes = useStyles()

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h6" className={classes.titleText}>
          {`${label}`}
        </Typography>
        {isLoading ? (
          <Box className={classes.loadingBox}>
            <CircularProgress color="inherit" size={"2rem"} />
          </Box>
        ) : (
          <StyledToggleButtonGroup
            value={!dataIn[field] ? "ON" : "OFF"}
            exclusive
            onChange={handleSelectedChange}
          >
            <StyledToggleButtonOff size="large" value={"OFF"}>
              {"OFF"}
            </StyledToggleButtonOff>
            <StyledToggleButtonOn size="large" value={"ON"}>
              {"ON"}
            </StyledToggleButtonOn>
          </StyledToggleButtonGroup>
        )}
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby={`change-ss-${field}-title`}
        aria-describedby={`change-ss-${field}-description`}
      >
        <DialogTitle
          id={`change-ss-${field}-title`}
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>
            {"Change store settings"}
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{ root: classes.dialogText }}
            id={`change-ss-${field}-description`}
          >
            {`Are you sure you want to turn ${
              dataIn[field] ? "On" : "Off"
            } the ${label} system?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.but }}
              >
                <Typography variant="body1">{"No"}</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleChange}
                variant="contained"
                size="large"
                disableElevation
                fullWidth
                classes={{ root: classes.but }}
              >
                <Typography variant="body1">{"Yes"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
