import React from "react"
import Slider from "@material-ui/core/Slider"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import toTime from "../../../../helperFunc/toTime"

import { makeStyles, withStyles } from "@material-ui/core/styles"
//import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
  mainGrid: {
    paddingTop: "2.5rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingBottom: "0",

    backgroundColor: ({ index }) =>
      Math.abs(index % 2) === 1
        ? theme.palette.common.blackFour
        : "transparent",
  },
  sliderBox: {
    width: "100%",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },

  textGrid: { marginBottom: "1rem" },

  headingText: {
    fontSize: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
  },
}))

const PrettoSlider = withStyles(theme => ({
  root: {
    color: theme.palette.common.redSelected,
    height: 8,
  },
  disabled: { color: theme.palette.common.redSelected, height: 8 },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: `4px solid ${theme.palette.common.blackThree}`,
    marginTop: -10,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "-25px",
    top: "-20px",
    "& *": {
      transform: "rotate(180deg) !important",
      borderRadius: "0.25rem !important",
    },

    "&:first-child > span": {
      height: "1.25rem",
      minWidth: "4.25rem",
    },
  },

  track: {
    height: 4,
    borderRadius: 4,
  },
  mark: {
    backgroundColor: theme.palette.common.black,
    marginTop: -2,
    height: 8,
    width: 1,
  },
  markActive: {
    marginTop: -2,
    opacity: 0.75,
    backgroundColor: theme.palette.common.redSelectedDark,
    width: 2,
  },
  markLabel: {
    fontSize: "0.75rem",
  },
  rail: {
    height: 4,
    backgroundColor: theme.palette.common.blackOne,
  },
}))(Slider)

export default function TimeComp({
  timeObj,
  setTimeObj,
  openKey,
  closeKey,
  name,
  nameShort,
  index,
}) {
  const classes = useStyles({ index })

  //const matchesMD = useMediaQuery(theme => theme.breakpoints.down("sm"))
  let marks = []

  for (let i = 0; i <= 24; i++) {
    marks.push({
      value: i * 60,
      label: ``,
    })
  }

  const handleChange = (event, newValue) => {
    let timeObjCopy = { ...timeObj }
    timeObjCopy[openKey] = newValue[0]
    timeObjCopy[closeKey] = newValue[1]
    setTimeObj(timeObjCopy)
  }

  function valuetext(value) {
    return toTime(value)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      classes={{ root: classes.mainGrid }}
    >
      <Grid item xs={12} xl={1} classes={{ root: classes.textGrid }}>
        <Typography variant="h5" classes={{ root: classes.headingText }}>
          {name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box classes={{ root: classes.sliderBox }}>
          <PrettoSlider
            value={[timeObj[openKey], timeObj[closeKey]]}
            onChange={handleChange}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaLabel={valuetext}
            valueLabelFormat={valuetext}
            step={10}
            marks={marks}
            min={0}
            max={1440}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
